import { sendRewardAsync } from '@dx-ui/framework-conductrics';
import { CarouselSingle } from '@dx-ui/osc-carousel';

import { CONDUCTRICS_GOALS } from '../../helpers/constants';
import { getSingleCarouselImages } from '../../helpers/utils/client';
import { ImageStrip } from '../image-strip';

import type { GetHotelHomePageQuery } from '@dx-ui/queries-dx-property-ui';

export type InspiredHeroProps = {
  images: NonNullable<NonNullable<GetHotelHomePageQuery['hotel']>['images']>['carousel'];
};

const sendCarouselEngagementReward = async () => {
  await Promise.allSettled([
    sendRewardAsync(CONDUCTRICS_GOALS.HERO_IMAGE_CAROUSEL_ENGAGEMENT),
    sendRewardAsync(CONDUCTRICS_GOALS.HERO_IMAGE_CAROUSEL_ENGAGEMENT_TOTAL),
  ]);
};

const getCarouselImages = (carouselImages: NonNullable<InspiredHeroProps['images']>) =>
  carouselImages.map((carouselImage) => ({
    altText: carouselImage?.altText || '',
    desktopImageUrl: carouselImage?.variants.find((variant) => variant.size === 'md')?.url || '',
    mobileImageUrl: carouselImage?.variants.find((variant) => variant.size === 'sm')?.url || '',
  }));

const InspiredHero = ({ images }: InspiredHeroProps) => (
  <>
    <div className="block lg:hidden">
      <CarouselSingle
        images={getSingleCarouselImages(images)}
        onArrowClick={sendCarouselEngagementReward}
      />
    </div>
    <div className="hidden lg:block">
      <ImageStrip images={getCarouselImages(images)} onArrowClick={sendCarouselEngagementReward} />
    </div>
  </>
);

export default InspiredHero;
